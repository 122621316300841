export default class Index{
	constructor(){
		if(document.body.classList.contains("index")){
			this.initSlideshow();
			this.initStickyNav();
			this.initNewsNoticeToggle();
			this.initDoctorToggle();
			this.initMedicalSwitch();
			this.initDiabetesSlide();
			this.initFlowSwitch();
			this.initCalender();
		}
	}

	initSlideshow(){
		const slideshow = document.querySelector(".index__slideshow");
		const slides = document.querySelectorAll(".index__slideshow__images__image");
		const pages = document.querySelectorAll(".index__slideshow__pager__page");
		const wait = 5000;
		let position = 0;
		let autoSlideTimer = null;

		function slide(num){
			if(num < 0) num = slides.length-1;
			if(num >= slides.length) num = 0;
			position = num;
			slideshow.setAttribute("data-position", position);
			if(autoSlideTimer){
				clearTimeout(autoSlideTimer);
				autoSlideTimer = setTimeout(autoSlide, wait);
			}
		}
		function autoSlide(){
			slide(position+1);
		}

		pages.forEach(page=>{
			page.addEventListener("click", function(){
				slide(page.getAttribute("data-num")*1);
			}, false);
		});
		autoSlideTimer = setTimeout(autoSlide, wait);
	}
	initStickyNav(){
		const nav = document.querySelector(".index__nav");
		const sections = Array.from(document.querySelectorAll(".index__nav__link")).map(link=>{
			const href = link.getAttribute("href");
			let section = null;
			if(href.length > 1 && href.indexOf('#') == 0){
				if(document.querySelector(href)){
					section = document.querySelector(href);
				}
			}
			return {
				link: link,
				section: section
			}
		});
		let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
		let isStick = false;
		let isDraw = false;

		function scrollHandler(){
			const rect = nav.getBoundingClientRect();
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			const toTop = lastScrollTop > scrollTop;

			if(!toTop && !isStick && rect.top<=0){
				isStick = true;
				nav.classList.add("stick");
				nav.classList.add("noDuration");
				setTimeout(()=>{ nav.classList.remove("noDuration"); }, 10);
			}else if(toTop && isStick){
				if( (!isDraw && rect.top>0) || (isDraw && rect.top>120) ){
					isStick = false;
					isDraw = false;
					nav.classList.remove("stick");
					nav.classList.remove("draw");
					nav.classList.add("noDuration");
					setTimeout(()=>{ nav.classList.remove("noDuration"); }, 10);
				}
			}
			if(toTop && isStick && !isDraw && rect.top<=0 && !document.body.classList.contains("anchorScrolling")){
				isDraw = true;
				nav.classList.add("draw");
			}else if(toTop && isStick && isDraw && rect.top<=0 && document.body.classList.contains("anchorScrolling")){
				isDraw = false;
				nav.classList.remove("draw");
			}else if(!toTop && isStick && isDraw && rect.top<=0){
				isDraw = false;
				nav.classList.remove("draw");
			}

			for(var i=sections.length; i--; i>=0){
				const section = sections[i];
			}

			lastScrollTop = scrollTop;
		}


		setTimeout(()=>{
			scrollHandler();
		},100);
		setTimeout(()=>{
			lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
			window.addEventListener("scroll", scrollHandler, false);
		},1000);
	}
	initNewsNoticeToggle(){
		const notices = document.querySelectorAll(".index__headline__notice");
		notices.forEach(notice=>{
			const title = notice.querySelector(".index__headline__notice__title");
			const main = notice.querySelector(".index__headline__notice__main");
			const content = notice.querySelector(".index__headline__notice__main__content");
			let isOpen = false;

			function toggle(){
				if(isOpen){
					notice.classList.remove("open");
					isOpen = false;
				}else{
					notice.classList.add("open");
					isOpen = true;
				}
				resizeHandler();
			}
			function resizeHandler(){
				if(isOpen){
					main.style.height = content.offsetHeight+"px";
				}else{
					main.style.height = "0px";
				}
			}

			title.addEventListener("click", toggle, false);
			window.addEventListener("resize", resizeHandler, false);
		});
	}
	initDoctorToggle(){
		const doctors = document.querySelectorAll(".index__introduction__doctor");
		doctors.forEach(doctor=>{
			const btn = doctor.querySelector(".index__introduction__doctor__toggle");
			const container = doctor.querySelector(".index__introduction__doctor__container");
			const content = doctor.querySelector(".index__introduction__doctor__contents");
			let isOpen = false;
			
			function toggle(){
				if(isOpen){
					doctor.classList.remove("open");
					isOpen = false;
				}else{
					doctor.classList.add("open");
					isOpen = true;
				}
				resizeHandler();
			}
			function resizeHandler(){
				if(isOpen){
					container.style.height = content.offsetHeight+"px";
				}else{
					container.style.height = "96px";
				}
			}

			btn.addEventListener("click", toggle, false);
			window.addEventListener("resize", resizeHandler, false);	
		})
	}
	initMedicalSwitch(){
		const medical = document.querySelector(".index__medical");
		const blocks = medical.querySelectorAll(".index__medical__body__block");
		blocks.forEach(block=>{
			const num = block.dataset.num;
			const title = block.querySelector(".index__medical__body__block__title");
			title.addEventListener("click", ()=>{
				medical.setAttribute("data-position", num);
			}, false);
		});
	}
	initDiabetesSlide(){
		const diabetes = document.querySelector(".index__diabetes");
		const viewport = diabetes.querySelector(".index__diabetes__viewport");
		const container = diabetes.querySelector(".index__diabetes__container");
		const pages = diabetes.querySelectorAll(".index__diabetes__pager__page");
		let position = 0;
		let isDragging = null;
		let startX,startY,moveX,moveY = 0;

		const start = window.isTouch ? "touchstart" : "mousedown";
		const move = window.isTouch ? "touchmove" : "mousemove";
		const end = window.isTouch ? "touchend" : "mouseup";
		const leave = window.isTouch ? "touchleave" : "mouseleave";

		function touchStartHandler(e){
			e.stopPropagation();
			isDragging = false;
			startX = e.pageX;
			startY = e.pageY;
		}
		function touchMoveHandler(e){
			if(isDragging === null) return false;
			if(isDragging === true) e.preventDefault();
			moveX = e.pageX;
			moveY = e.pageY;
			if(isDragging === false){
				if(!window.isTouch || Math.abs(moveX - startX) > Math.abs(moveY - startY)){
					isDragging = true;
					container.classList.add("dragging");
				}else{
					isDragging = null;
				}
			}
			if(isDragging){
				container.style.transform = "translateX(calc("+(-100*position)+"% + "+(-50*position+(moveX-startX)/2)+"px))";
			}
		}
		function touchEndHandler(e){
			if(isDragging === true) {
				if((moveX - startX) > 0){
					prev();
				}else{
					next();
				}
				container.classList.remove("dragging");
				container.removeAttribute("style");
			}
			isDragging = null;
		}

		function next(){
			position = Math.min(position+1, 2);
			diabetes.setAttribute("data-position", position);
		}
		function prev(){
			position = Math.max(position-1, 0);
			diabetes.setAttribute("data-position", position);
		}

		pages.forEach(page=>{
			const num = page.dataset.num*1;
			page.addEventListener("click", ()=>{
				position = num;
				diabetes.setAttribute("data-position", position);
			}, false);
		})
		viewport.addEventListener(start, touchStartHandler, false);
		viewport.addEventListener(move, touchMoveHandler, false);
		viewport.addEventListener(end, touchEndHandler, false);
		viewport.addEventListener(leave, touchEndHandler, false);
	}
	initFlowSwitch(){
		const flow = document.querySelector(".index__flow");
		const blocks = document.querySelectorAll(".index__flow__group");
		blocks.forEach(block=>{
			const pos = block.classList.contains("first") ? 0 : 1;
			const label = block.querySelector(".index__flow__label");
			label.addEventListener("click", ()=>{
				flow.setAttribute("data-position", pos);
			}, false);
		});
	}
	initCalender(){
		const viewport = document.querySelector(".index__schedule__calender__viewport");
		const blocks = document.querySelectorAll(".index__schedule__calender__block");
		const num = blocks.length;
		let position = 0;

		blocks.forEach(block=>{
			const prev = block.querySelector(".index__schedule__calender__move.prev");
			const next = block.querySelector(".index__schedule__calender__move.next");
			prev.addEventListener("click", ()=>{
				position = Math.max(position-1, 0);
				viewport.setAttribute("data-position", position);
			}, false);
			next.addEventListener("click", ()=>{
				position = Math.min(position+1, num-1);
				viewport.setAttribute("data-position", position);
			}, false);
		}, false);
	}
}



