export default class Header{
	constructor(){
		this.initialize();
	}

	initialize(){
		const header = document.querySelector(".header");
		const toggle = header.querySelector(".header__toggle");
		let startPosition = document.body.classList.contains("index") ? 160 : 120;
		let endPosition = document.body.classList.contains("index") ? 20 : 0;
		let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
		let isStick = false;
		let isDraw = false;
		let isOpen = false;
		let layout = (window.innerWidth>=768) ? "pc" : "sp";

		function scrollHandler(){
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			const toTop = lastScrollTop > scrollTop;

			if(!toTop && !isStick && scrollTop>=startPosition){
				isStick = true;
				header.classList.add("stick");
				header.classList.add("noDuration");
				setTimeout(()=>{ header.classList.remove("noDuration"); }, 10);
			}else if(toTop && isStick){
				if( (!isDraw && scrollTop<startPosition) || (isDraw && scrollTop<=endPosition) ){
					isStick = false;
					isDraw = false;
					header.classList.remove("stick");
					header.classList.remove("draw");
					header.classList.add("noDuration");
					setTimeout(()=>{ header.classList.remove("noDuration"); }, 10);
				}
			}

			if(toTop && isStick && !isDraw && scrollTop>=startPosition && !document.body.classList.contains("anchorScrolling")){
				isDraw = true;
				header.classList.add("draw");
			}else if(toTop && isStick && isDraw && scrollTop>=startPosition && document.body.classList.contains("anchorScrolling")){
				isDraw = false;
				header.classList.remove("draw");
			}else if(!toTop && isStick && isDraw && scrollTop>=startPosition){
				isDraw = false;
				header.classList.remove("draw");
			}

			lastScrollTop = scrollTop;
		}
		function resizeHandler(){
			if(layout == "sp"){
				startPosition = 130;
				endPosition = 0;
			}else{
				startPosition = document.body.classList.contains("index") ? 160 : 120;
				endPosition = document.body.classList.contains("index") ? 20 : 0;
			}	
		}
		function toggleHeader(){
			if(isOpen){
				header.classList.remove("open");
				isOpen = false;
			}else{
				header.classList.add("open");
				isOpen = true;
			}
		}

		toggle.addEventListener("click", toggleHeader, false);
		window.addEventListener("resize", resizeHandler, false);
		resizeHandler();
		setTimeout(()=>{
			scrollHandler();
		},100);
		setTimeout(()=>{
			lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
			window.addEventListener("scroll", scrollHandler, false);
		},1000);
		

		if(document.body.classList.contains("index")){
			const links = document.querySelectorAll(".header__nav__link");
			links.forEach(link=>{
				link.addEventListener("click", toggleHeader, false);
			});
		}
	}
}