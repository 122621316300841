import 'nodelist-foreach-polyfill';
import AnchorScroll from './modules/_anchorScroll.js';
import Index from './modules/_index.js';
import Header from './modules/_header.js';

class App {
	constructor() {
		window.hdc_anchorscroll = new AnchorScroll();
		window.hdc_index = new Index();
		window.hdc_header = new Header();
	}
}

document.addEventListener('DOMContentLoaded', () => {
	window.isTouch = false;
	if( navigator.userAgent.indexOf('iPhone') > 0        ||
		navigator.userAgent.indexOf('iPod') > 0          ||
		navigator.userAgent.indexOf('iPad') > 0          ||
		navigator.userAgent.indexOf('Windows Phone') > 0 ||
		navigator.userAgent.indexOf('Android') > 0 ) {
		window.isTouch = true;
		document.body.classList.add('touch');
	}
	let app = new App();
	try{Typekit.load({active:function(){
		console.log("typekit loaded");
	}});}catch(e){
		console.log(e);
	}
}, false);